import React from 'react';
import { FaRegCopyright, FaCode, FaLinkedin, FaGithub } from 'react-icons/fa';
import { DiCode } from 'react-icons/di';
import styles from './index.module.sass';

const now = new Date();

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.credits}>
      <FaRegCopyright />
      &nbsp;
      {`Klondike Group ${now.getFullYear()}`}
      &nbsp; | &nbsp; Créé par &nbsp;
      <a
        href="https://www.linkedin.com/in/antoine-demonchaine/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Antoine Demon-Chaine
      </a>
      &nbsp;
      <a
        href="https://www.linkedin.com/in/antoine-demonchaine/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FaLinkedin className={styles.linkedinLogo} />
      </a>
    </div>
    <div className={styles.creditsMobile}>
      <div>
        <FaRegCopyright />
        &nbsp;
        {`Klondike Group 2019 - ${now.getFullYear()}`}
      </div>
      <div>
        Créé par Antoine Demon-Chaine &nbsp;
        <a
          href="https://www.linkedin.com/in/antoine-demonchaine/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FaLinkedin className={styles.linkedinLogo} />
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
