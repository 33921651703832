import { Link } from 'gatsby';
import React from 'react';
import styles from './index.module.sass';

const Header = () => (
  <header className={styles.header}>
    <div className={styles.container}>
      <h1 className={styles.h1}>
        <Link
          className={styles.link}
          to="/"
        >
          Klondike Group
        </Link>
      </h1>
    </div>
  </header>
);

export default Header;
